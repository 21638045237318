import React from 'react';
import styles from 'Custom/ISI/ISI.module.scss';

export const INDICATION_SHORT_BLOCK = (
  <>
    <h2 className={styles.plainHeading}>Indicación</h2>
    <p className={styles.indicationBlock}>
      NEXPLANON es un medicamento recetado para la prevención del embarazo en
      mujeres.
    </p>
    <h2 className={styles.plainHeading}>Información de Seguridad Importante</h2>
  </>
);

export const CONTENT_BLOCK = (
  <>
    <p>
      No debe usar NEXPLANON si está embarazada o cree que puede estarlo, tiene
      o ha tenido coágulos de sangre, tiene enfermedad hepática o un tumor de
      hígado, tiene sangrado vaginal sin motivo aparente, tiene o ha tenido
      cáncer de mama o algún otro cáncer sensible a la progestina (una hormona
      femenina) o es alérgica a algún componente de NEXPLANON.
    </p>
    <p>
      Hable con un profesional de la salud acerca del uso de NEXPLANON si tiene
      diabetes, colesterol o triglicéridos elevados, dolores de cabeza,
      problemas de la vesícula biliar o de los riñones, antecedente de estado
      depresivo, presión arterial alta, o alergia a los medicamentos que se usan
      para anestesiar (anestésicos) o a los medicamentos que se usan para
      limpiar la piel (antisépticos). Estos medicamentos se utilizarán al
      colocar o extraer el implante del brazo.
    </p>
    <p>
      <strong>
        Inmediatamente después de la colocación del implante NEXPLANON, usted y
        el profesional de la salud deben palpar el brazo para comprobar que el
        implante esté allí. Si en algún momento no puede palpar el implante
        NEXPLANON, comuníquese de inmediato con su profesional de la salud y
        utilice un método anticonceptivo no hormonal (como condones) hasta que
        el profesional de la salud confirme que el implante está en su lugar.
      </strong>{' '}
      Es posible que se necesiten pruebas especiales para comprobar que el
      implante está en su lugar o como ayuda para encontrarlo cuando sea el
      momento de extraerlo.
    </p>
    <p>
      Es posible que el implante no quede colocado en el brazo debido a una
      colocación fallida. Si esto sucede, podría quedar embarazada. La
      extracción del implante puede resultar bastante difícil o imposible si el
      implante no está donde debería. Es posible que sean necesarios
      procedimientos especiales, incluida una cirugía en el hospital, para
      extraer el implante. Si no se extrae el implante, los efectos de NEXPLANON
      se prolongarán por más tiempo. También pueden presentarse otros problemas
      relacionados con la inserción y la extracción, que incluyen reacción
      vasogenal (como caída de la presión arterial, mareos o desfallecimiento),
      dolor, irritación, hinchazón, formación de hematomas, entumecimiento y
      hormigueo, formación de cicatriz, infección, lesión en los nervios o vasos
      sanguíneos, y rotura del implante. Además, el implante puede salirse solo.
      Puede quedar embarazada si el implante se sale solo. Utilice un método
      anticonceptivo de respaldo y llame a su profesional de la salud de
      inmediato si el implante se sale.
    </p>
    <p>
      El efecto secundario más frecuente de NEXPLANON es un cambio en su patrón
      normal de sangrado menstrual. En estudios, una de cada diez mujeres dejó
      de usar el implante por un cambio desfavorable en su patrón de sangrado.
      Puede experimentar un sangrado más prolongado o más breve durante sus
      períodos, o no tener ningún sangrado. El tiempo entre períodos puede
      variar, y también puede experimentar manchado entre los períodos.
    </p>
    <p>
      Si queda embarazada mientras utiliza NEXPLANON, tiene una probabilidad un
      poco más alta de que el embarazo sea ectópico (fuera del útero) que las
      mujeres que no utilizan métodos anticonceptivos. Los embarazos ectópicos
      pueden provocar sangrado interno grave, infertilidad e incluso la muerte.
      Llame de inmediato a su profesional de la salud si cree que está
      embarazada o tiene un dolor en la parte inferior del estómago (abdominal)
      sin motivo aparente.
    </p>
    <p>
      El uso de NEXPLANON también puede aumentar su probabilidad de que
      aparezcan coágulos de sangre graves, especialmente si hay otros factores
      de riesgo, como fumar. Si fuma y desea utilizar NEXPLANON, debe dejar de
      fumar. Los coágulos de sangre pueden generarse dentro de los vasos
      sanguíneos de diferentes partes del cuerpo, incluidas las piernas
      (trombosis venosa profunda), los pulmones (embolia pulmonar), el cerebro
      (accidente cerebrovascular), el corazón (ataque cardíaco) y los ojos
      (ceguera parcial o total). Es posible morir por un problema provocado por
      un coágulo de sangre, como un ataque cardíaco o un accidente
      cerebrovascular. Informe al profesional de la salud, al menos 4 semanas
      antes, si va a someterse a una cirugía o si necesitará hacer reposo en
      cama, dado que tiene mayores probabilidades de sufrir coágulos de sangre
      durante la cirugía o el reposo en cama.
    </p>
    <p>
      Es posible que se desarrollen quistes en los ovarios, los que a menudo
      desaparecen sin tratamiento, pero a veces es necesario recurrir a la
      cirugía para extirparlos.
    </p>
    <p>
      Además de cambios en los patrones de sangrado menstrual, otros efectos
      secundarios frecuentes informados en mujeres que usan NEXPLANON incluyen:
      dolores de cabeza; vaginitis (inflamación de la vagina); aumento de peso;
      acné; dolor en los senos; infecciones virales, como dolor de garganta o
      síntomas semejantes a la gripe; dolor de estómago; períodos menstruales
      dolorosos; cambios del estado de ánimo; nerviosismo o estado depresivo;
      dolor de espalda; náuseas; mareos; dolor y dolor en el lugar de la
      inserción. Se han informado casos de implantes que se han encontrado en
      los vasos sanguíneos, incluidos los vasos sanguíneos del pulmón.
    </p>
    <p>
      Llame de inmediato al profesional de la salud si tiene dolor que no cesa
      en la parte inferior de la pierna; dolor de pecho intenso u opresión en el
      pecho; dificultad repentina para respirar, fuerte dolor de pecho o tos con
      sangre; síntomas de una reacción alérgica grave, como hinchazón de la
      cara, la lengua o la garganta, dificultad para tragar o para respirar;
      dolores de cabeza intensos y repentinos distintos de los dolores de cabeza
      habituales; debilidad o adormecimiento en el brazo o la pierna, o
      dificultad para hablar; ceguera repentina parcial o total; color
      amarillento de la piel o la parte blanca de los ojos, especialmente
      acompañado de fiebre, cansancio, pérdida del apetito, orina de color
      oscuro o heces de color claro; dolor intenso, hinchazón o sensibilidad en
      la parte inferior del estómago (abdomen); bulto en el seno; problemas para
      dormir, falta de energía, cansancio o mucha tristeza; sangrado menstrual
      abundante; o si considera que es posible que el implante se haya roto o
      doblado mientras se encontraba en el brazo.
    </p>
    <h3 className={styles.plainHeading}>
      NEXPLANON no protege contra el VIH ni otras ETS.
    </h3>
  </>
);

const Accompanying = ({ noriId, moduleTitle }) => (
  <p className={styles.bold}>
    Lea la{' '}
    <a
      className="terms"
      href="/static/pdf/PPI.pdf"
      target="_blank"
      data-design-type="clickAction"
      data-design-category="content_link_vd"
      data-design-action="Download"
      data-design-label="Información para el Paciente"
      data-design-nori_id={noriId}
      data-design-module_name={moduleTitle}
      data-design-module_no="00"
    >
      Información para el Paciente
    </a>{' '}
    o{' '}
    <a
      className="terms"
      href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_ppi.pdf"
      target="_blank"
      data-design-type="clickAction"
      data-design-category="content_link_vd"
      data-design-action="Download"
      data-design-label="Patient Information"
      data-design-nori_id={noriId}
      data-design-module_name={moduleTitle}
      data-design-module_no="00"
    >
      Patient Information
    </a>{' '}
    de NEXPLANON adjunta y analícela con el profesional de la salud. También
    está disponible la{' '}
    <a
      className="terms"
      href="/static/pdf/PI.pdf"
      target="_blank"
      data-design-type="clickAction"
      data-design-category="content_link_vd"
      data-design-action="Download"
      data-design-label="Información de Prescripción"
      data-design-nori_id={noriId}
      data-design-module_name={moduleTitle}
      data-design-module_no="00"
    >
      Información de Prescripción
    </a>{' '}
    o{' '}
    <a
      className="terms"
      href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_pi.pdf"
      target="_blank"
      data-design-type="clickAction"
      data-design-category="content_link_vd"
      data-design-action="Download"
      data-design-label="Prescribing Information"
      data-design-nori_id={noriId}
      data-design-module_name={moduleTitle}
      data-design-module_no="00"
    >
      Prescribing Information
    </a>{' '}
    para el profesional de la salud.
  </p>
);

export const ISI_REPORT = {
  firstPart:
    'Se le recomienda que informe a la FDA (Administración de Alimentos y Medicamentos) acerca de los efectos secundarios negativos de los fármacos recetados. Visite',
  secondPart: 'o llame al',
  Accompanying,
};

export const ISI_CONTENT = {
  variant: 'alternative',
  INDICATION_SHORT_BLOCK,
  CONTENT_BLOCK,
  ISI_REPORT,
};
