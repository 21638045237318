import React from 'react';
import Container from 'Nori/Container';
import Button from 'Nori/Button';
import styles from 'Custom/Finder/FinderSimple.module.scss';

const FinderSimple = ({
  title = '',
  className = '',
  designModuleNo = '00',
  buttonLabel = '',
  buttonHref = '/',
  children,
  ...props
}) => {
  return (
    <div
      className={`${styles.finderSimple} ${className}`}
      data-design-visible="visible_vd"
      data-design-type="module"
      data-design-nori_id="Inline Content"
      data-design-module_name={title}
      data-design-module_no={designModuleNo}
      {...props}
    >
      <Container className={styles.finderContainer}>
        <div className={styles.finderContent}>
          <h3 className={styles.finderTitle}>{title}</h3>
          <div className={styles.finderCopy}>{children}</div>
        </div>
        {buttonLabel && (
          <Button
            type="primaryLight"
            size="small"
            href={buttonHref}
            data-design-type="clickAction"
            data-design-category="button_vd"
            data-design-action="Button"
            data-design-label={buttonLabel}
            data-design-nori_id="Inline Content"
            data-design-module_name={title}
            data-design-module_no={designModuleNo}
            className={styles.finderButton}
          >
            {buttonLabel}
          </Button>
        )}
      </Container>
    </div>
  );
};

export default FinderSimple;
