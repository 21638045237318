export const HEALTHCARE_MODAL_CONTENT = {
  ARIA: {
    closeModal: 'Cerrar Modal',
  },
  title: '¿Eres profesional de la salud?',
  children: (
    <p>
      Esta sección contiene información destinada únicamente a profesionales de
      la salud de Estados Unidos y no está destinada al público en general.
    </p>
  ),
  returnButton: {
    children: 'No, no lo soy. Por favor, quiero regresar.',
    'data-design-label': 'No, no lo soy. Por favor, quiero regresar.',
  },
  confirmButton: {
    href: 'https://organonpro.com/en-us/product/nexplanon/overview/',
    children: 'Sí, lo soy.',
    'data-design-label': 'Sí, lo soy.',
    caption: 'Sitio en inglés',
  },
  bottomContent: (
    <>
      <p>
        Este sitio está destinado únicamente a residentes de los Estados Unidos,
        sus territorios y Puerto Rico.
      </p>
      <p>
        {`© ${new Date().getFullYear()}`} Grupo de empresas Organon. Todos los
        derechos reservados.
      </p>
    </>
  ),
};
