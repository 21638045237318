import React from 'react';
import styles from 'Custom/ISIBar/ISIBar.module.scss';

export const ISI_INTRO = (
  <span className="firstPart">
    No debe usar NEXPLANON si está embarazada o cree que puede estarlo, tiene o
    ha tenido coágulos de sangre, tiene enfermedad hepática o un tumor de
    hígado, tiene sangrado vaginal sin motivo aparente, tiene o ha tenido cáncer
    de mama o algún otro cáncer sensible a la progestina (una hormona femenina)
    o es alérgica a algún componente de NEXPLANON.
  </span>
);

export const ISI_BAR_CONTENT = {
  ARIA: {
    openIndication: 'Abrir Indicación completa',
    openISI: 'Abrir Información de Seguridad Importante completa',
  },
  indicationTitle: 'Indicación',
  indicationIntro:
    'NEXPLANON es un medicamento recetado para la prevención del embarazo en mujeres.',
  indicationMobile: (
    <>
      <span className={styles.highlight}>INDICACIÓN:</span> NEXPLANON es un
      medicamento recetado para la prevención del embarazo en mujeres.
    </>
  ),
  isiTitle: 'Información de Seguridad Importante',
  isiIntro: ISI_INTRO,
  isiMobile: (
    <>
      <span className={styles.highlight}>
        INFORMACIÓN DE SEGURIDAD IMPORTANTE:
      </span>{' '}
      {ISI_INTRO}
    </>
  ),
};
